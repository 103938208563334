import { ApplicationRef, Component, Inject, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import {
  AlertController,
  ModalController,
  NavController,
  Platform,
  PopoverController,
  ToastController
} from '@ionic/angular';
import { concat, interval } from 'rxjs';
import { filter, map, first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SplashScreen } from '@capacitor/splash-screen';
import { Storage } from '@capacitor/storage';
import mixpanel from 'mixpanel-browser';
//import { OneSignal } from '@ionic-native/onesignal/ngx';
//import OneSignal from 'onesignal-cordova-plugin';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { App, URLOpenListenerEvent } from '@capacitor/app';
//import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
// services
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from './services/auth.service';
import { EventService } from './services/event.service';
import { AccountsService } from './services/logged-in/accounts.service';
import { LanguageService } from './services/language.service';
import { TranslateLabelService } from './services/translate-label.service';
import { StoreService } from './services/logged-in/store.service';
import { OrdersService } from './services/logged-in/orders.service';
//pages
import { HeaderProfilePopupPage } from "./modals/header-profile-popup/header-profile-popup.page";
import { HeaderShopPopupPage } from "./modals/header-shop-popup/header-shop-popup.page";
import { OrderAlertComponent } from './components/order-alert/order-alert.component';
import { WoyouConsts, BarCodeType, SunmiDevice } from 'plugn-device';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router, RouterState } from '@angular/router';
import OneSignal from 'onesignal-cordova-plugin';
import { CampaignService } from './services/logged-in/campaign.service';
import { AnalyticsService } from './services/analytics.service';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';


declare var window: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  @ViewChild('menuLTR', { static: false }) menuLTR;
  @ViewChild('menuRTL', { static: false }) menuRTL;

  public updatesAvailable: boolean = false;

  public notificationScriptLoaded: boolean = false;

  private orderSubscription;

  public orderAlert;

  public showStores = false;

  constructor(
    public zone: NgZone,
    public appRef: ApplicationRef,
   // public oneSignal: OneSignal,
    public platform: Platform,
    public authService: AuthService,
    public popoverCtrl: PopoverController,
    public modalCtrl: ModalController,
    public eventService: EventService,
    public analyticsService: AnalyticsService,
    public accountService: AccountsService,
    public translateService: TranslateLabelService,
    public languageService: LanguageService,
    public datePipe: DatePipe,
    public storeService: StoreService,
    public orderService: OrdersService,
    public cookieService: CookieService,
    public campaignService: CampaignService,
    public navCtrl: NavController,
    public alertCtrl: AlertController,
    public updates: SwUpdate,
    public toastCtrl: ToastController,
    public auth: Auth0Service,
    public router: Router,
    @Inject(DOCUMENT) public document: Document,
  ) {
  }

  getTitle(state: RouterState, parent: ActivatedRoute): string[] {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data['title']) {
      data.push(parent.snapshot.data['title']);
    }
    if (state && parent && parent.firstChild) {
      data.push(...this.getTitle(state, parent.firstChild));
    }
    return data;
  }

  handleRouteEvents() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        //const title = this.getTitle(this.router.routerState, this.router.routerState.root).join('-');
        //this.titleService.setTitle(title);
        gtag('event', 'page_view', {
          //page_title: title,
          page_path: event.urlAfterRedirects,
          page_location: this.document.location.href
        })
      }
    });
  }

  initializeApp() {
   
    // use hook after platform dom ready
    this.platform.ready().then(() => {
      GoogleAuth.initialize({
        //clientId: '876118421973-gs2r5han4mftkf3kjdbvnf1uegvt89b3.apps.googleusercontent.com',
        clientId: '876118421973-bhut8gnlo51u6loreh8oi6drhvotocap.apps.googleusercontent.com',

        scopes: ['profile', 'email'],
        grantOfflineAccess: false,
      }) 
    });
    
    mixpanel.init(environment.mixpanelKey, {
      debug: false, 
      track_pageview: true, 
      //persistence: 'localStorage',
      loaded: (mixpanel) => {
        this.authService.mixpanel_distinct_id = mixpanel.get_distinct_id();
      }
    });
    
    if(this.authService.isLogged && this.authService.agent_name) {

      const id = this.authService.id;//this.authService.store_id? this.authService.store_id: 
 
      this.analyticsService.user(id, {
        name: this.authService.agent_name,
        email: this.authService.agent_email,
        created_at: this.authService.created_at
      });


    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    
    if(urlParams.get('auth_key')) {
      this.authService.loginByKey(urlParams.get('auth_key'), urlParams.get('store_id'));
    }

    if(urlParams.get('utm_id')) {
      this.authService.utm_uuid = urlParams.get('utm_id');
      Storage.set({ key: 'utm_uuid', value: this.authService.utm_uuid });
      this.campaignService.click(this.authService.utm_uuid).subscribe();

      //this.cookieService.set('utm_uuid', this.authService.utm_uuid, )
      window.localStorage.setItem("utm_uuid", this.authService.utm_uuid);

      mixpanel.track("From Campaign", {
        "utm_id": this.authService.utm_uuid,
        "utm_source": urlParams.get('utm_source'),
        "utm_medium": urlParams.get('utm_medium'),
        "utm_campaign": urlParams.get('utm_campaign'),
        "utm_term": urlParams.get('utm_term'),
        "utm_content": urlParams.get('utm_content'),
      });
    }

    this.handleRouteEvents();

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
          // Example url: https://beerswift.app/tabs/tab2
          // slug = /tabs/tab2

          // If no match, do nothing - let regular routing
          // logic take over

          //if (event.url?.startsWith(callbackUri)) {
            // If the URL is an authentication callback URL..

            if (
              event.url.includes('state=') &&
              (event.url.includes('error=') || event.url.includes('code='))
            ) {
              // Call handleRedirectCallback and close the browser
              this.auth
                .handleRedirectCallback(event.url)
                //.pipe(mergeMap(() => Browser.close()))
                .subscribe((result) => {
                });
            } else {
              const slug = event.url.split(".io").pop();

              if (slug) {
                this.router.navigateByUrl(slug);
              }

              //Browser.close();
            }
          //}
      });
    });

    Storage.get({ key: 'cookieMessageWasApproved' }).then(ret => {
      if (ret.value == '0' || !ret.value) {
        this.authService.displayCookieMessage = 1;
      }
    }).catch(r => {
      this.eventService.errorStorage$.next({ error: r });
    });

    window.onpopstate = e => {

      if (window['history-back-from'] == 'onDidDismiss') {
        window['history-back-from'] = null;
        return false;
      }

      Promise.all([
        this.popoverCtrl.getTop(),
        this.modalCtrl.getTop()
      ])
        .then(data => {

          if (data[0]) {
            this.popoverCtrl.dismiss({
              from: 'native-back-btn'
            });
          }

          if (data[1]) {
            this.modalCtrl.dismiss({
              from: 'native-back-btn'
            });
          }
        });
    };

    setTimeout(async () => {

      /**
       * todo: need to test in mobile app
       * when user comming back from auth0
       */
      this.auth.isAuthenticated$.subscribe(isAuthenticated => {

        if(!isAuthenticated || this.authService.isLogged) return null;

        //this.auth.idTokenClaims$.subscribe(r => {
        this.auth.getAccessTokenSilently().subscribe(r => {
          this.authService.useTokenForAuth(r).then();
        });
      });

      //if payment failed

      let message = this.cookieService.get('paymentFailed');

      if (message && message.length > 0) {

        document.cookie = 'paymentFailed=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=' + environment.cookieDomain + ';';
        this.cookieService.delete('paymentFailed', '/', environment.cookieDomain);

        const alert = await this.alertCtrl.create({
          header: this.translateService.transform('Alert'),
          message: this.translateService.transform('There seems to be an issue with your payment, please try again.'),
          buttons: [this.translateService.transform('Okay')]
        });

        await alert.present();
      }

      //if payment success

      let messagePaymentSuccess = this.cookieService.get('paymentSuccess');

      if (messagePaymentSuccess && messagePaymentSuccess.length > 0) {

        document.cookie = 'paymentSuccess=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=' + environment.cookieDomain + ';';
        this.cookieService.delete('paymentSuccess', '/', environment.cookieDomain);

        const alert = await this.alertCtrl.create({
          header: this.translateService.transform('Success'),
          message: this.translateService.transform('Plan has been activated'),
          //messagePaymentSuccess
          buttons: [this.translateService.transform('Okay')]
        });

        await alert.present();
      }

      //if addon payment success

      let messageAddonPaymentSuccess = this.cookieService.get('addonPaymentSuccess');

      if (messageAddonPaymentSuccess && messageAddonPaymentSuccess.length > 0) {

        document.cookie = 'addonPaymentSuccess=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=' + environment.cookieDomain + ';';
        this.cookieService.delete('addonPaymentSuccess', '/', environment.cookieDomain);

        const alert = await this.alertCtrl.create({
          header: this.translateService.transform('Success'),
          message: this.translateService.transform('We received your payment, We will contact you for next step!'),
          //messageAddonPaymentSuccess
          buttons: [this.translateService.transform('Okay')]
        });

        await alert.present();
      }

    }, 100);//let translator get initialized

    this.platform.ready().then(() => {

      if (this.platform.is('capacitor')) {
        SplashScreen.hide();
      } else {
        this.setServiceWorker();
      }
    });

    window.addEventListener('online', () => this.eventService.internetOnline$.next({}));
  }

  /**
   * Using Ng2 Lifecycle hooks because view lifecycle events don't trigger for Bootstrapped MyApp Component
   */
  async ngOnInit() {

    this.initializeApp();

    //to fix : https://www.pivotaltracker.com/story/show/172176267

    if (this.platform.is('capacitor') && this.platform.is('ios')) {
    //  this.oneSignal.provideUserConsent(false);
    }

    if (!this.authService.currentLocation) { 
        this.authService.locate().subscribe(res => {
          
          this.authService.currentLocation = res; 

          if(this.authService.currentLocation) {
            Storage.set({ key: 'currentLocation', value: JSON.stringify(res) });

            this.eventService.locationUpdated$.next(res);
          }
        });
    }

    //this.platform.ready().then(() => {

    setTimeout(() => {

      if (this.platform.is('capacitor') && this.platform.is('mobile')) {

        this._initOneSignal();

        // only when notification api available

      } else if (window && window.Notification) {
        this._includeOneSignalJs();
      }
    }, 100);

    //});

    this.authService.audioPlayer.src = 'assets/audio/bell.mp3';
    this.authService.audioPlayer.muted = true;
    this.authService.audioPlayer.loop = true;
    this.authService.audioPlayer.autoplay = true;
    this.authService.audioPlayer.volume = 0;
    //this.authService.audioPlayer.play();

    this.authService.audioPlayer.autoplay = true;
    this.authService.audioPlayer.volume = 0;
    //this.authService.audioPlayer.play();

    //if already logged in

    if (this.authService.isLogged) {
      this.subscribeToOrders();
      //this._includeOneSignalJs();
    }

    /**
     * Save user language preference after login
     */
    this.eventService.setLanguagePref$.subscribe(language_pref => {

      /**
       * changing status on `side` property change
       * https://github.com/ionic-team/ionic/blob/master/core/src/components/menu/menu.tsx
       *
      */

      this.languageService.listToTranslate().subscribe(languages => {

        for (const element of languages) {
          if (element.code == language_pref) {
            // change language

            this.translateTo(element);

            break;
          }
        }
      });
    });

    this.eventService.error404$.subscribe(data => {
      this.navCtrl.navigateForward(['not-found']);
    });

    this.eventService.error500$.subscribe(data => {
      this.navCtrl.navigateForward(['server-error'], {
        state: {
          message: data ? data['message'] : null
        }
      });
    });

    // Check for network connection
    this.eventService.internetOffline$.subscribe(async () => {
      this.toastCtrl.create({
        message: this.translateService.transform('Sorry, no Internet connectivity detected. Please reconnect and try again.'),
        duration: 3000,
        position: 'bottom'
      }).then(toast => toast.present());

      this.unsubscribeOrders();
      this.navCtrl.navigateForward(['/no-internet']);
    });

    this.eventService.errorStorage$.subscribe(eventData => {
      this.navCtrl.navigateRoot(['app-error']);
    });

    this.eventService.errorBlocked$.subscribe(eventData => {
      this.navCtrl.navigateRoot(['ip-blocked-error']);
    });

    // On Login Event, set root to Internal app page
    this.eventService.userLogined$.subscribe((userEventData: any) => {
  
      /*this.analyticsService.track("Log In", {
        login_method: channel
      })*/

      if(this.authService.isLogged && this.authService.agent_name) {

        const id = this.authService.id;//this.authService.store_id? this.authService.store_id: 
   
        this.analyticsService.user(id, {
          name: this.authService.agent_name,
          email: this.authService.agent_email,
          created_at: this.authService.created_at
        });
      }
 
      this.oneSignalActionBasedOnStatus();

      this.platform.ready().then(() => {
        this.subscribeToOrders();
      });

      // update company list (invitation + employer access request + current employers)

      this.loadStores();

      if (!this.authService.store && this.authService.store_id) {
        this.loadStoreDetail();
      }

      /**
       * if (!this.auth.employer_uuid) {
            this.gotoRoot(['company-option']);
       */

      //TODO: check if getting called on load, else load user info from ngOnInit

      if (userEventData.redirect) {
        
        if(this.authService.store_id)
         this.navCtrl.navigateRoot(['/']);
        else 
          this.navCtrl.navigateRoot(['/store-create']);
      }

      this.loadUserInfo();
    });

    /**
     * Update one signal setting to manage subscription for mobile
     * notification
     */
    this.eventService.setOneSignalSubscription$.subscribe(userEventData => {
      /*window.plugins.OneSignal.promptForPushNotificationsWithUserResponse((accepted) => {
        console.log("User accepted notifications: " + accepted);

        if(accepted) {

          window.plugins.OneSignal.sendTags({
            'agent_id': this.authService.id + '',
            'name': this.authService.agent_name,
            'email': this.authService.agent_email
          });

          this.authService.showOneSignalPrompt = false;

        } else {
          this.authService.showOneSignalPrompt = true;

        }
      });*/

     //this.oneSignal.setSubscription(userEventData['setSubscription']);

      Storage.set({
        key: 'oneSignal',
        value: JSON.stringify(userEventData)
      }).catch(r => {
        this.eventService.errorStorage$.next({});
      });
    });

    this.eventService.setOneSignal$.subscribe(() => {
      this.setOneSignalSubscription();
    });

    // On Logout Event, set root to Login Page
    this.eventService.userLoggedOut$.subscribe((logoutReason) => {

      if(logoutReason != "invalid access") {
        this.analyticsService.track("Log Out", {
          reason: logoutReason
        });
        this.analyticsService.refresh();
      }

      this.unsubscribeOrders();

      // Set root to Login Page
      this.navCtrl.navigateRoot(['/login']);

      /*this.auth.isAuthenticated$.subscribe(isAuthenticated => {
        if(isAuthenticated) {
          this.auth.logout({ returnTo: document.location.origin });
        }
      });*/

      // unsubscribe from oneSignal

      if (this.platform.is('capacitor') && this.platform.is('mobile')) {

        OneSignal.deleteTags(['name', 'email', 'agent_id']);

        /*OneSignal.addPermissionObserver(data => {

          /**
           * 0 - "NotDetermined" - The user hasn't yet made a choice about whether the app is allowed to schedule notifications.
           * 1 - "Denied" - The app isn't authorized to schedule or receive notifications.
           * 2 - "Authorized" - The app is authorized to schedule or receive notifications.
           * 3 - "Provisional" - The application is provisionally authorized to post noninterruptive user notifications. See iOS Customizations
           * 4 - "Ephemeral" - For App Clips. The app is authorized to schedule or receive notifications for a limited amount of time.
           *
          if (data.to.status == 2 || data.to.status == 3) {
            OneSignal.deleteTags(['name', 'email', 'agent_id']);
          }
        });*/
      }
      else if (window && window.Notification && window.OneSignal) {

        const OneSignal = window.OneSignal;

        OneSignal.isPushNotificationsEnabled(isEnabled => {

          if (isEnabled) {

            // Delete user tags if subscribed

            OneSignal.getUserId().then(userId => {

              if (userId) {

                const tags = [
                  'agent_id',
                  'name',
                  'email'
                ];

                OneSignal.deleteTags(tags);
              }
            });
          }
        });
      }

      // Show Message explaining logout reason if there's one set
      if (logoutReason) {
        console.log(logoutReason);
      }
    });

    // ------------------------- store management --------------------------

    /**
     * Update menu on back to online
     */
    this.eventService.internetOnline$.subscribe(() => {

      if (this.authService.isLogged) {

        this.loadStores();

        this.loadStoreDetail();

        /*if (!this.authService.profile_complete) {
          this.eventService.profileCompleteRequired$.next({});
        } else
        if (!this.auth.employer_uuid) {
          this.gotoRoot(['company-option']);
        } else {
          this.gotoRoot(['view']);
        }*/

        this.navCtrl.navigateRoot(['/']);

      } else {
        this.navCtrl.navigateRoot(['/login']);
        // Set root to Login Page
      }

      // in case app got open in offline and then go online

      /*if (!this.authService.currentLocation) {
        this.guessLocation();
      }*/
    });

    /*this.eventService.roleChanged$.subscribe(data => {
      this.loadCompanies();
      this.loadEmployerDetail();
    });*/

    // on employer access request sent

    this.eventService.refreshStoreList$.subscribe(() => {
      this.loadStores();
    });

    this.eventService.noStoreFound$.subscribe(() => {
      this.authService.setStore(null, null);
      this.authService.logout();
    });

    // change company when agent got un-assigned

    this.eventService.accountAssignmentRemoved$.subscribe(() => {

      // remove current employer from employer list

      const a = [];

      for (const b of this.authService.stores) {
        if (b.restaurant_uuid !== this.authService.store_id) {
          a.push(b);
        }
      }

      this.authService.stores = a;

      if (this.authService.stores.length > 0) {
        this.eventService.storeChanged$.next({
          store: this.authService.stores[0]['restaurant'],
          role: this.authService.stores[0].role
        });
      } else {
        this.eventService.noStoreFound$.next({});
      }
    });

    //todo: storeCreated$

    this.eventService.storeCreated$.subscribe(data => {

      this.resetStoreDetail(data['store'], data['role']);
      
      this.subscribeToOrders();
    
      this.loadStores();
    });

    //todo: storeUnAssigned$
    this.eventService.storeUnAssigned$.subscribe(data => {

      // display company-option page to either create new company or get invited

      if (!data || !data['store']) {
        return this.eventService.noStoreFound$.next({});
      }

      // set new employer

      this.resetStoreDetail(data['store'], data['role']);

      // update company list when agent leave team

      this.loadStores();

      // refresh view

      if (this.authService.store_id) {
        this.navCtrl.navigateRoot(['/']);
      } else {
        this.authService.logout();
        //this.gotoRoot(['company-option']);
      }
    });

    this.eventService.storeChanged$.subscribe(data => {

      // reset total count for new store
      this.authService.totalPendingOrders = null;

      const redirect = (data['redirect']) ? data['redirect'] : 'view';
      this.changeStore(data['store'], data['role'], redirect);
    });

    this.eventService.storeUpdated$.subscribe(data => {
      this.loadStoreDetail();
      this.loadStores();
    });

    /**
    * Print Invoice ( Sunmi Device )
    */
    this.eventService.printInvoice$.subscribe(async (params: any) => {

      let order = params['order'];

      if(params['printTwoInvoices'] == true){
        this.printInvoiceOnSunmiDevice(order).then(res=>{
          this.printInvoiceOnSunmiDevice(order).then();
        })
      } else {
        this.printInvoiceOnSunmiDevice(order);
      }

    });
  }

  wrapText(text, maxLength) {
    let wordsArr = text.split(" "),
      wordsLen = wordsArr.length,
      output = "",
      linesArr = [""],
      currLine = 0;

    for (let i = 0; i < wordsLen; i++) {
      if (linesArr[currLine].length + wordsArr[i].length > maxLength) {
        currLine += 1;
        linesArr[currLine] = wordsArr[i] + " ";
      } else {
        linesArr[currLine] += wordsArr[i] + " ";
      }
    }
    let linesLen = linesArr.length;
    for (let i = 0; i < linesLen; i++) {
      output += linesArr[i].trim() + "\n";
    }
    return output.trim();
  }


  async printInvoiceOnSunmiDevice(order : any){

    let branchName;

    if(order.order_mode == 1 && order.delivery_zone_id && order.businessLocation.business_location_name != null){
      branchName =  order.businessLocation.business_location_name;
    }
    else if (order.order_mode == 2 && order.pickupLocation && order.pickupLocation && order.pickupLocation.business_location_name){
      branchName =  order.pickupLocation.business_location_name;
    }

    await SunmiDevice.initPrinter();
    await SunmiDevice.boldOn();
    await SunmiDevice.setAlignment({ alignment: 1 });


    await SunmiDevice.printLine({ text: '' });

    await SunmiDevice.printTextWithFont({ text: this.wrapText(order.restaurant.name  , 19) + "\n", fontSize: 40 });
    await SunmiDevice.printTextWithFont({ text: this.wrapText( branchName, 25) + "\n", fontSize: 27 });
    // await SunmiDevice.printTextWithFont({ text: order.restaurant.name  + branchName, fontSize: 40 });



    await SunmiDevice.printTextWithFont({ text: '#' + order.order_uuid + '\n', fontSize: 40 });


    await SunmiDevice.printLine({ text: '-------------------------------' });



    // Customer
    await SunmiDevice.printTextWithFont({ text: "Customer:\n", fontSize: 35 });
    await SunmiDevice.printTextWithFont({ text: order.customer_name + "\n", fontSize: 30 });
    await SunmiDevice.printTextWithFont({ text: order.customer_phone_number + "\n", fontSize: 30 });



    await SunmiDevice.printLine({ text: '-------------------------------' });

    if (order.order_mode == 1) {


      await SunmiDevice.printTextWithFont({ text: "Address:\n", fontSize: 35 });

      let address = '';

      if (order.area_id && order.area_name) {
        // await SunmiDevice.printTextWithFont({ text: order.area_name + ",", fontSize: 30 });

        address += order.area_name;

        // await SunmiDevice.printTextWithFont({ text: this.wrapText(order.area_name + ", ",25) , fontSize: 27 });

      }


      if (order.area_id && order.block) {
        // await SunmiDevice.printTextWithFont({ text: "Block:" + order.block + ",", fontSize: 30 });
        address += ", Block:" + order.block + ", ";

        // await SunmiDevice.printTextWithFont({ text: this.wrapText("Block:" + order.block + ", ",25) , fontSize: 27 });

      }

      if (order.area_id && order.street) {
        // await SunmiDevice.printTextWithFont({ text: "Street:" + order.street + ",", fontSize: 30 });

        address += "Street:" + order.street + ", ";


        // await SunmiDevice.printTextWithFont({ text: this.wrapText("Street:" + order.street + ", ",25) , fontSize: 27 });

      }

      if (order.area_id && order.avenue) {
        // await SunmiDevice.printTextWithFont({ text: "Avenue:" + order.avenue + ",", fontSize: 30 });

        address += "Avenue:" + order.avenue + ", ";


        // await SunmiDevice.printTextWithFont({ text: this.wrapText("Avenue:" + order.avenue + ", ",25) , fontSize: 27 });

      }

      if (order.unit_type?.toLowerCase() == 'apartment' || order.unit_type?.toLowerCase() == 'office') {


        if (order.area_id && order.apartment != null && order.unit_type?.toLowerCase() == 'apartment') {
          // await SunmiDevice.printTextWithFont({ text: "Apartment: " + order.apartment + ",", fontSize: 30 });

        address += "Apartment:" + order.apartment+ ", ";


        // await SunmiDevice.printTextWithFont({ text: this.wrapText("Apartment:" + order.apartment+ ", " ,25) , fontSize: 27 });

        }

        if (order.area_id && order.office != null && order.unit_type?.toLowerCase() == 'office') {

        address += "Office:" + order.office+ ", ";


        // await SunmiDevice.printTextWithFont({ text: this.wrapText("Office:" + order.office+ ", " ,25) , fontSize: 27 });

        }


      }


      if (order.area_id && order.address_1) {
        // await SunmiDevice.printTextWithFont({ text: order.address_1 + ",", fontSize: 30 });

        address += order.address_1+ ", " ;


        // await SunmiDevice.printTextWithFont({ text: this.wrapText( order.address_1+ ", " ,25) , fontSize: 27 });

      }
      if (order.area_id && order.address_2) {
        // await SunmiDevice.printTextWithFont({ text: order.address_2 + ",", fontSize: 30 });

        address += order.address_2 + ", " ;

        // await SunmiDevice.printTextWithFont({ text: this.wrapText( order.address_2+ ", " ,25) , fontSize: 27 });

      }


      if (order.area_id) {
        // await SunmiDevice.printTextWithFont({ text: " Building: " + order.house_number + "\n", fontSize: 30 });

        address += " Building: " + order.house_number;

        // await SunmiDevice.printTextWithFont({ text: this.wrapText(  " Building: " + order.house_number   ,25) + '\n', fontSize: 27 });

      }
      await SunmiDevice.printTextWithFont({ text: this.wrapText( address ,25) + '\n', fontSize: 27 });


    } else {
     await SunmiDevice.printTextWithFont({ text: "Pickup \n", fontSize: 35 });
    }


    await SunmiDevice.printLine({ text: '-------------------------------' });
    if (order.payment_method_id != 3) {
      await SunmiDevice.printTextWithFont({ text: 'Paid with ' + order.payment_method_name + "\n", fontSize: 35 });
    } else {
      await SunmiDevice.printTextWithFont({ text: order.payment_method_name + "\n", fontSize: 35 });
    }

    await SunmiDevice.printLine({ text: '-------------------------------' });

    await SunmiDevice.setAlignment({ alignment: 0 });

    for (const item of order.orderItems) {

      let itemPrice;

      if (item.item_price > 0)
        //  itemPrice =  item.currency?.code+item.item_price;
        itemPrice = item.item_price;
      else
        itemPrice = '--';


      await SunmiDevice.setFontSize({ fontSize: 30 });

      // await SunmiDevice.printColumnsText({ colsTextArr: [item.qty + 'x ' ,item.item_name, itemPrice], colsWidthArr: [2, 14, 14], colsAlign: [0, 1, 2] });
      // await SunmiDevice.printColumnsText({ colsTextArr: [item.qty + 'x ' ,item.item_name, itemPrice], colsWidthArr: [2, 14, 14], colsAlign: [0, 1, 2] });

      let itemName: string[] = this.wrapText(item.item_name, 15).split("\n");

      if (itemName.length > 0) {
        await SunmiDevice.printColumnsText({ colsTextArr: [item.qty + 'x ', itemName[0], itemPrice], colsWidthArr: [3, 15, 7], colsAlign: [0, 0, 0] });

        for (let i = 1; i < itemName.length; i++) {
          await SunmiDevice.printColumnsText({ colsTextArr: ['', itemName[i], ''], colsWidthArr: [3, 15, 7], colsAlign: [0, 0, 0] });
        }
      }


      if (item.customer_instruction) {
        //await SunmiDevice.printColumnsText({ colsTextArr: ['***' + item.customer_instruction, ''], colsWidthArr: [25, 5], colsAlign: [0, 0] });
      }


      if (item.orderItemExtraOptions) {
        for (const extraOption of item.orderItemExtraOptions) {
          await SunmiDevice.printTextWithFont({ text: this.wrapText(' - ' + extraOption.extra_option_name,25) + '\n', fontSize: 27 });
        }

      }

    }

    await SunmiDevice.printLine({ text: '-------------------------' });

    if (order.voucher && order.voucher.discount_type !== 3)
      await SunmiDevice.printTextWithFont({ text: 'Subtotal: ' + order.currency?.code + this.getSubtotalAfterDiscount(order) + '\n', fontSize: 33 });
    else
      await SunmiDevice.printTextWithFont({ text: 'Subtotal: ' + order.currency?.code + order.subtotal_before_refund + '\n', fontSize: 33 });

    if (order.voucher && order.voucher.discount_type == 3)
      await SunmiDevice.printTextWithFont({ text: 'Delivery Fee: ' + order.currency?.code + parseFloat('0'.toString()).toFixed(3) + '\n', fontSize: 33 });
    else
      await SunmiDevice.printTextWithFont({ text: 'Delivery Fee: ' + order.currency?.code + order.delivery_fee + '\n', fontSize: 33 });

    await SunmiDevice.printLine({ text: '' });

    await SunmiDevice.printTextWithFont({ text: 'Total: ' + order.currency?.code + order.total_price_before_refund + '\n', fontSize: 40 });


    await SunmiDevice.printLine({ text: '-------------------------' });

    await SunmiDevice.printLine({ text: '' });


    await SunmiDevice.setAlignment({ alignment: 1 });
    await SunmiDevice.printTextWithFont({ text: this.datePipe.transform(order.order_created_at, 'MMM d, y') + "\n", fontSize: 25 });
    await SunmiDevice.printTextWithFont({ text: 'Ordered at ' + this.datePipe.transform(order.order_created_at, 'h:mm a') + "\n", fontSize: 25 });

    await SunmiDevice.setAlignment({ alignment: 0 });

    await SunmiDevice.printLine({ text: '' });

    await SunmiDevice.printLine({ text: '' });
    await SunmiDevice.setAlignment({ alignment: 1 });
    await SunmiDevice.feedPaper();

    await SunmiDevice.boldOff();
  }

  getSubtotalAfterDiscount(order) {
    return order.voucher.discount_type == 1 ?
      parseFloat((order.subtotal_before_refund - (order.subtotal * (order.voucher.discount_amount / 100))).toString()).toFixed(3) : parseFloat((order.subtotal_before_refund - order.voucher.discount_amount).toString()).toFixed(3);
  }

  /**
   * set oneSignal subscription for browser
   */
  async setOneSignalSubscription() {

    this.analyticsService.track("Notification Settings Updated", {
      notifications_enabled: true
    });

    if (this.platform.is('capacitor') && this.platform.is('mobile')) {

      OneSignal.setAppId(environment.oneSignalAppId);

      OneSignal.sendTags({
        'agent_id': this.authService.id + '',
        'name': this.authService.agent_name,
        'email': this.authService.agent_email
      });

    }
    else if (window && window.Notification && window.OneSignal) {
      const OneSignal = window.OneSignal || [];

      OneSignal.setSubscription(true);
      OneSignal.registerForPushNotifications();

      // send user tag, to target based on tags

      const tags = {
        'agent_id': this.authService.id + '',
        'name': this.authService.agent_name,
        'email': this.authService.agent_email
      };

      OneSignal.sendTags(tags);
    } else {
      console.log('window.Notification && window.OneSignal');
    }

    this.authService.showOneSignalPrompt = false;

    Storage.set({
      'key': 'oneSignalStatus',
      'value': '1'
    }).catch(r => {
      console.error(r);
      this.eventService.errorStorage$.next({});
    });
  }

  /**
   * check oneSignal subscription status to show prompt in conversation list page
   */
  async oneSignalActionBasedOnStatus() {

    Storage.get({ 'key': 'oneSignalStatus' }).then(data => {

      if (data.value === '1') { // already accepted
        this.setOneSignalSubscription();
      } else { // not sure
        this.checkOneSignalStatus();
      }
      // if status == 2, ignore - user not want notifications
    }).catch(r => {
      this.eventService.errorStorage$.next({});
    });
  }

  /**
   * check oneSignal subscription status for browser
   */
  async checkOneSignalStatus() {

    if (this.platform.is('capacitor') && this.platform.is('mobile')) {
      //todo: check onesignal on new app install + after login tags + after logout tags + on permission denied
      //+ on permission grant + send test notification + send order notif

      //as we not have send tags

      this.authService.showOneSignalPrompt = true;

    } else if (window && window.OneSignal && window.Notification) {

      const OneSignalw = window.OneSignal || [];

      OneSignalw.isPushNotificationsEnabled(isEnabled => {

        if (isEnabled) {

          // Automatically subscribe user if deleted cookies and browser shows "Allow"

          OneSignalw.getUserId().then(userId => {

            // remove old user tag if any

            if (userId) {

              const tags = [
                'agent_id',
                'name',
                'email'
              ];

              OneSignalw.deleteTags(tags);
            }

            // if (!userId) {

            OneSignalw.setSubscription(true);
            OneSignalw.registerForPushNotifications();

            // send user tag, to target based on tags

            const tags = {
              'agent_id': this.authService.id + '',
              'name': this.authService.agent_name,
              'email': this.authService.agent_email
            };

            OneSignalw.sendTags(tags);

            // }
          });
        } else {
          this.authService.showOneSignalPrompt = true;
        }
      });

      // Occurs when the user's subscription changes to a new value.

      OneSignalw.on('subscriptionChange', isSubscribed => {
        this.authService.showOneSignalPrompt = !isSubscribed;
      });
    }
  }

  /**
   * Include One signal to use stripe element in browser
   */
  async _includeOneSignalJs() {

    if (this.platform.is('capacitor') || !window.Notification || window.location.hostname == 'localhost') {
      return null; // only for browser
    }

    /**
     * https://sentry.io/organizations/pogi/issues/1843000885/?project=5339282&referrer=slack
     * Cannot read property 'pushNotification' of undefined
     */

    const agent = window.navigator.userAgent.toLowerCase();

    if (this.platform.is('ios') && agent.indexOf('safari') > -1 && (!window.safari || !window.safari.pushNotification)) {
      return null; // ios browser not supporting push notification
    }

    // if already loaded, just update tags

    if (window.OneSignal) {
      return this.oneSignalActionBasedOnStatus();
    }

    // if already initialized

    if (this.notificationScriptLoaded) {
      return null;
    }

    this.notificationScriptLoaded = true;

    // load script and call callback to initialize

    const callback = _ => {

      const wOneSignal = window.OneSignal || [];

      wOneSignal.push(_ => {

        // initialize only on first time script load

        wOneSignal.init({
          appId: environment.oneSignalAppId,
          safari_web_id: environment.oneSignalSafariAppId,
          autoRegister: false,
          httpPermissionRequest: {
            enable: false
          },
          promptOptions: {
            customlink: {
              enabled: true
            }
          }
        });

        this.oneSignalActionBasedOnStatus();
      });
    };

    this.loadScript('https://cdn.onesignal.com/sdks/OneSignalSDK.js', callback);
  }

  /**
   * Subscribe to oneSignal notification api for capacitor app
   */
  _initOneSignal() {

    OneSignal.setAppId(environment.oneSignalAppId);

    OneSignal.addSubscriptionObserver(state => {

      if (state) {
        // !state.from.subscribed &&
        if (state.to.isSubscribed) {
          // this.authService.showOneSignalPrompt = false;
          // Subscribed for OneSignal push notifications!
          // get player ID
          // state.to.userId

        } else {
          this.authService.showOneSignalPrompt = true;
        }

        // console.log('Push Subscription state changed: ' + JSON.stringify(state));
      }
    });

    OneSignal.addPermissionObserver(data => {

      /**
       * 0 - "NotDetermined" - The user hasn't yet made a choice about whether the app is allowed to schedule notifications.
       * 1 - "Denied" - The app isn't authorized to schedule or receive notifications.
       * 2 - "Authorized" - The app is authorized to schedule or receive notifications.
       * 3 - "Provisional" - The application is provisionally authorized to post noninterruptive user notifications. See iOS Customizations
       * 4 - "Ephemeral" - For App Clips. The app is authorized to schedule or receive notifications for a limited amount of time.
       */
      if (data.to.status == 2 || data.to.status == 3) {
        this.authService.showOneSignalPrompt = false;
      } else if (data.to.status == 0) {
        this.authService.showOneSignalPrompt = true;
      }
    });

    //setNotificationOpenedHandler().subscribe
    /*
    this.oneSignal.handleNotificationOpened().subscribe((data) => {

      console.log('setNotificationOpenedHandler', data);

      // When a Notification is Opened
      if (data.notification.groupedNotifications) {
        // Notification Grouped [on Android]
        const firstNotificationData = data.notification.groupedNotifications[0];
        //this.eventService.notificationGrouped$.next(firstNotificationData);
      } else {
        // A single notification clicked
        const notificationData = data.notification;
        //this.eventService.notificationSingle$.next(notificationData);
      }
    });*/

    //this.setOneSignalSubscription();

    this.oneSignalActionBasedOnStatus();
  }

  /**
   * Load javascripts dynamically
   * @param url
   * @param callback
   */
  async loadScript(url: string, callback = null) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;

    if (callback) {
      script.addEventListener('load', callback);
    }

    body.appendChild(script);
  }

  ngOnDestroy(): void {
    // this.authService.stopTheBell();
    this.unsubscribeOrders();
  }

  unsubscribeOrders() {
    if (this.orderSubscription) {
      clearInterval(this.orderSubscription);
      this.orderSubscription = null;
    }
  }

  subscribeToOrders() {

    if (!this.authService.store_id || this.orderSubscription || !this.authService._accessToken) {
      return false;
    }

    //check sound notification permission

    let ele = document.getElementById('btn-check-bell');

    if (ele) {
      setTimeout(() => {
        ele.click();
      }, 200);
    }


    this.orderSubscription = setInterval(() => {
      this.isNewOrderAvailable();
    }, 10 * 1000);//10 seconds

    this.isNewOrderAvailable();
  }

  /**
   * load store list
   */
  async loadStores() {

    this.accountService.listStores().subscribe(response => {

      this.authService.stores = response;


      /*if (this.authService.stores.length > 0 && !this.oneSignalIncluded) {

        this._storage.get('oneSignalStatus').then(status => {
          if (status !== 2) {
            this.oneSignalIncluded = true;
            this._includeOneSignalJs();
          }
        });
      }*/

      if (this.authService.stores.length && this.authService.store_id) {

        const found = this.authService.stores.find((data, key) => {
          if (data.restaurant_uuid == this.authService.store_id) {
            return true;
          }
        });

        if (!found) {
          if (this.authService.stores[0]['restaurant']) {
            this.eventService.storeChanged$.next({
              store: this.authService.stores[0]['restaurant'],
              role: this.authService.stores[0].role
            });
          } else {
            this.eventService.storeChanged$.next({ store: null, role: null });
          }
        }

      }
    });

    /*
    // load invitation pending to accept

    this._invitationService.pending().subscribe(response => {
      this.auth.invitations = response;
    });

    // list EmployerAccessRequest

    this.requestService.list().subscribe(response => {
      this.auth.employerAccessRequest = response;
    });*/
  }

  /**
   * change store request
   * @param store
   * @param redirect
   */
  async changeStore(store, role, redirect = 'view') {

    this.resetStoreDetail(store, role);

    if (store) {
      // show jobs page
      this.navCtrl.navigateRoot(['/'], {
        state: {
          store_id: store?.restaurant_uuid
        }
      });
    }
  }

  /**
   * rest store detail
   */
  async resetStoreDetail(store, role) {

    this.authService.store_id = store.restaurant_uuid;

    const expand = "totalItems,totalOrders,isCashEnabled,isKnetEnabled,isCreditCardEnabled,isMadaEnabled,isBenefitEnabled,isMashkorEnabled,isArmadaEnabled";

    this.storeService.detail(expand).subscribe(response => {
      this.authService.setStore(response, response.role);
    });

    /*
    this.authService.setStore(store, role);

    clearInterval(this.alertSubscription);

    this.alertSubscription = null;

    if (employer) {
      this.alertSubscribe();
    }*/
  }

  /**
   * load store detail
   */
  async loadStoreDetail() {

    if (!this.authService.store_id) {
      return this.resetStoreDetail(null, null);
    }

    //todo: fetch role from api

    this.storeService.detail().subscribe(response => {
      this.resetStoreDetail(response, response.role);
    });
  }

  /**
   * Change app language
   * @param language
   */
  translateTo(language) {

    this.translateService.use(language.code).subscribe();

    if(this.authService.isLogged && this.authService.language_pref != language.code)
    {
      this.accountService.updateLanguagePref(language.code).subscribe();
    }

    this.authService.setLanguagePref(language);

    if (language.code == 'ar') {
      document.querySelector('html').setAttribute('dir', 'rtl');
      document.querySelector('body').setAttribute('class', 'rtl');
    } else {
      document.querySelector('html').setAttribute('dir', 'ltr');
      document.querySelector('body').setAttribute('class', 'ltr');
    }
  }

  /**
  * When user select refresh on udpate available prompt
  */
  onUpdateAlertRefresh() {

    if (!this.updatesAvailable) {
      return this.updatesAvailable = false;
    }

    try {
      this.updates.activateUpdate().then(() => {
      });
    } catch {
    }

    window.location.reload();
  }

  /**
   * When user select close on udpate available prompt
   */
  onUpdateAlertClose() {
    this.updatesAvailable = false;
  }

  /**
   * keep checking for service worker update
   */
  setServiceWorker() {

    // service worker watcher
    if (!this.platform.is('capacitor') && window.location.hostname != 'localhost') {

      if ('serviceWorker' in navigator && environment.serviceWorker) {

        //navigator.serviceWorker.register('./ngsw-worker.js');

        // Allow the app to stabilize first, before starting polling for updates with `interval()`.
        const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
        const updateInterval$ = interval(60 * 1000);// every minute
        const updateIntervalOnceAppIsStable$ = concat(appIsStable$, updateInterval$);

        updateIntervalOnceAppIsStable$.subscribe(() => {
          this.updates.checkForUpdate().then((e) => {
          });
        });
          
        const updatesAvailable = this.updates.versionUpdates.pipe(
          filter((evt): evt is VersionReadyEvent => {
            return evt.type === 'VERSION_READY';
          }),
          map(evt => ({
            type: 'UPDATE_AVAILABLE',
            current: evt.currentVersion,
            available: evt.latestVersion,
          })));
  
        updatesAvailable.subscribe(() => {
          this.updatesAvailable = true;
        }); 
      }
    }
  }

  loadUserInfo() {
    this.accountService.get_userinfo().subscribe(response => {
      this.authService.agent_email = response.agent_email;
      this.authService.agent_name = response.agent_name;

      this.authService.saveInStorage();
    });
  }

  /**
   * ring bell by setting permission
   */
  startRinging() {

    this.authService.audioPlayer.muted = false;
    this.authService.audioPlayer.volume = 1;

    if(!this.authService.audioPlayer.played) {

      let ele = document.getElementById('btn-start-bell');

      if (ele) {
        ele.click();
      }

    } else {
      try {
        this.authService.audioPlayer.play();

        this.authService.ringTheBell();
      }
      catch (e) {
        console.log("error playing audio", e)
      }
      
    }
  }

  startBell() {
    this.authService.setStartBell();
    this.authService.audioPlayer.loop = true;
    this.authService.audioPlayer.autoplay = true;
    this.authService.audioPlayer.muted = false;
    this.authService.audioPlayer.volume = 1;

    this.authService.ringTheBell();
  }

  /**
   * check if new order available
   */
  isNewOrderAvailable() {

    //|| this.authService.setToSilenceMode

    if (!this.authService._accessToken) {
      return false;
    }

    /**
     * methods to check new orrders
     * 1. total order count got increase
     * 2. latest pedning order id updated
     * 3. order datetime
     */
    this.orderService.getTotalPendingCount().subscribe(async response => {

      //this.authService.totalPendingOrders &&
      //(response.totalPendingOrders > 0 && !this.authService.setToSilenceMode) ||
      //(response.totalPendingOrders > this.authService.totalPendingOrders)

      if(this.authService.latestOrderId != response.latestOrderId) {
        this.eventService.newOrder$.next(response);
        this.authService.latestOrderId = response.latestOrderId; //to avoid order listing refresh even when no new order (old pening orders)
      }

      /**
       * if pending order + not same notification + not silent
       */
      if (
        response.totalPendingOrders > 0 && response.latestPendingOrderId != this.authService.latestPendingOrderId 
        //&& !this.authService.setToSilenceMode
      ) {

        this.authService.latestPendingOrderId = response.latestPendingOrderId; //to avoid alert for same order

        if (!this.orderAlert && !this.authService.setToSilenceMode && response.latestPendingOrderId) {
          this.showOrderAlert(response);
        }
      }

      this.authService.totalPendingOrders = response.totalPendingOrders;

      this.authService.totalPendingInvoices = response.totalPendingInvoices;
    });
  }

  /**
   * show alert popup with alarm on new order
   */
  async showOrderAlert(response) {

    if (this.authService.ringBell != '0' && !this.authService.setToSilenceMode) {
      setTimeout(() => {
        this.startRinging();
      }, 2000);//2 second
    }

    this.orderAlert = true;

    this.orderService.view(response.latestPendingOrderId).subscribe(async order => {

      window.history.pushState({
        navigationId: window.history.state.navigationId
      }, null, window.location.pathname);

      this.orderAlert = await this.modalCtrl.create({
        component: OrderAlertComponent,
        cssClass: 'alert-new-order',
        componentProps: {
          order: order
        }
      });
      this.orderAlert.onDidDismiss().then(e => {

        if (!e.data || e.data.from != 'native-back-btn') {
          window['history-back-from'] = 'onDidDismiss';
          window.history.back();
        }

        //this.authService.setToSilenceMode = true;

        this.orderAlert = null;
        this.authService.audioPlayer.pause();
      });

      this.orderAlert.present();
    });
  }

  /**
   * chagne app language
   */
  async changeLanguage() {
    this.eventService.setLanguagePref$.next((this.authService.language.code == 'en') ? 'ar' : 'en');
    this.navCtrl.navigateRoot(['/']);
  }

  showStoresList(event) {
    //this.shopPopup(event);
    this.showStores = !this.showStores;
  }

  async shopPopup(ev) {

    window.history.pushState({
      navigationId: window.history.state.navigationId
    }, null, window.location.pathname);

    const popover = await this.popoverCtrl.create({
      component: HeaderShopPopupPage,
      animated: true,
      // cssClass: 'profile-popover',
      // mode: 'md',
      event: ev,
      translucent: true
    });
    popover.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }
    });
    return await popover.present();
  }

  async profilePopup(ev) {

    window.history.pushState({
      navigationId: window.history.state.navigationId
    }, null, window.location.pathname);

    const popover = await this.popoverCtrl.create({
      component: HeaderProfilePopupPage,
      animated: true,
      cssClass: 'profile-popover',
      mode: 'md',
      event: ev,
      translucent: true
    });
    popover.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }
    });

    return await popover.present();
  }

  /**
   * on logo load error
   */
  onLogoError() {
    this.authService.store.logo = null;
  }

  /**
   * When user click on close button in cookie message
   */
  onCookieMessageClose() {
    this.authService.displayCookieMessage = '0';
    Storage.set({
      key: 'cookieMessageWasApproved',
      value: '1'
    }).catch(r => {
      this.eventService.errorStorage$.next({ error: r });
    });
  }
}
