import { Injectable, RendererFactory2 } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, first, map, retryWhen, take } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { genericRetryStrategy } from '../util/genericRetryStrategy';
import { environment } from '../../environments/environment';
import { Storage } from '@capacitor/storage';
import mixpanel from 'mixpanel-browser';
import { AlertController, LoadingController } from '@ionic/angular';
import {
  SignInWithApple,
  SignInWithAppleResponse,
  SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
// service
import { EventService } from './event.service';
import { TranslateLabelService } from './translate-label.service';
//models
import { Restaurant } from '../model/restaurant';
//import { AnalyticsService } from './analytics.service';
import { SentryErrorhandlerService } from './sentry.errorhandler.service';


declare var navigator;
declare var AppleID;

@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanActivate {

  public auth0Token;

  public audioPlayer: HTMLAudioElement = new Audio();

  public showPlayButton = true;

  public ringBell = '1';

  public renderer;

  public _accessToken;
  public id: number;
  public agent_name: string;
  public agent_email: string;
  public created_at: string; 

  public role: number;

  public theme: string;

  public navEnable = true;

  public currency_pref = 'USD';

  public isLogged = false;

  public displayCookieMessage: any = '0';

  public showOneSignalPrompt = true;

  public store: Restaurant;

  public stores = [];//Restaurant[]

  public store_id;//selected store

  public language = {
    code: 'en',
    name: 'English',
  };

  // guest can have language_pref

  public language_pref: string;

  public latestOrderId; 
  public latestPendingOrderId; 
  
  public totalPendingOrders: number = 0;

  public totalPendingInvoices: number = 0; 
  
  public setToSilenceMode: boolean = false;
  public appleAuthLoading: boolean = false; 
  public currentLocation = null; 

  public utm_uuid; 

  public mixpanel_distinct_id; 

  public _urlLoginAuth0 = 'auth/login-auth0';
  private _urlBasicAuth = 'auth/login';
  private _urlSignup = 'auth/signup';
  private _urlSignupStepOne = 'auth/signup-step-one';
  private _urlUpdatePass = 'auth/update-password';
  private _urlResetPassRequest = 'auth/request-reset-password';

  public _urlresendVerificationEmail = 'auth/resend-verification-email';
  public _urlUpdateEmail = 'auth/update-email';
  public _urlIsEmailVerified = 'auth/is-email-verified';
  public _urlVerifyEmail = 'auth/verify-email';
  public _urlLocate = 'auth/locate';

  public _urlLoginByGoogle = 'auth/login-by-google';
  public urlLoginByApple = 'auth/login-by-apple';
  public _urlLoginByKey = 'auth/login-by-key';

  constructor(
    public _http: HttpClient,
    public router: Router,
    public alertCtrl: AlertController,
    public loadingCtrl: LoadingController,
    public eventService: EventService,
    public sentry: SentryErrorhandlerService,
    //public analyticsService: AnalyticsService,
    public translate: TranslateLabelService,
    public rendererFactory: RendererFactory2
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);

    //this.audioPlayer.setAttribute('playsinline', "true");
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
 
    /**
     * new router changes don't wait for startup service
     * https://github.com/angular/angular/issues/14615
     */
    return new Promise(resolve => {

      this.navEnable = true;

      if (route.data.navDisable) {
        this.navEnable = false;
      }

      if (this.isLogged) {
        resolve(true);
      }

      Storage.get({
        key: 'ringBell',
      }).then(ret => {
      });

      Storage.get({ key: 'loggedInAgent' }).then(ret => {

        const user = JSON.parse(ret.value);

        if (user && user.token) {
          this.isLogged = true;
          this._accessToken = user.token;
          this.id = user.id;
          this.agent_email = user.agent_email;
          this.agent_name = user.agent_name;
          this.created_at = user.created_at;
          this.role = user.role;
          this.theme = user.theme;
          this.store_id = user.store_id;

          // set language pref if user have valid language code saved in DB

          if (user.language_pref) {
            this.eventService.setLanguagePref$.next(user.language_pref);
          }

          resolve(true);
        } else {
          resolve(false);

          //this.router.navigateByUrl('/login');
          this.logout('invalid access');
        }

      }).catch(r => {
        this.eventService.errorStorage$.next({ error: r });
      });
    });
  }

  /**
   * return user location detail by user ip address
   * @return Observable
   */
  locate(): Observable<any> {
    const url = environment.apiEndpoint + this._urlLocate;
    const headers = this._buildAuthHeaders();
    return this._http.get(url, { headers: headers })
      .pipe(
        retryWhen(genericRetryStrategy()),
        catchError((err) => this._handleError(err)),
        take(1),
        map((res) => res)
      );
  }

  /**
   * Set language pref for current user
   */
  public setLanguagePref(language) {
    Storage.set({ key: 'language', value: JSON.stringify(language) })
      .catch((r) => {
      // this.eventService.errorStorage$.next({ error: r });
    });

    this.language = language;

    this.language_pref = language.code;

    if (this._accessToken) {
      this.saveInStorage();
    }
  }

  /**
   * @param auth_key 
   * @param store_id 
   * @returns 
   */
  async loginByKey(auth_key: string, store_id = null) {
    
    const loading = await this.loadingCtrl.create({
      spinner: 'crescent',
      message: this.translate.transform('Logging in...')
    });
    loading.present();

    const url = environment.apiEndpoint + this._urlLoginByKey;

    const headers = this._buildAuthHeaders();

    return this._http.post(url, {
      auth_key: auth_key,
      store_id: store_id
    }, {
      headers
    })
      .pipe(
        retryWhen(genericRetryStrategy()),
        catchError((err) => this._handleError(err)),
        first(),
        map((res) => res)
      )
      .subscribe(async response => {

        if (response.operation == 'success') {

          this.setAccessToken(response, true);

        } else if (response.operation == 'error') {
          const alert = await this.alertCtrl.create({
            message: this.translate.transform('Error getting login'), // JSON.stringify(err)
            buttons: [this.translate.transform('Okay')]
          });
          await alert.present();

        }

        //this.eventService.googleLoginFinished$.next({});

      }, err => {

        //this.eventService.googleLoginFinished$.next(err);
      },
      () => {
        if (loading) {
          loading.dismiss();
        }
      });
  }

  /**
   * login with AppleJS for PWA
   */
  async loginByAppleJs() {
    
    this.appleAuthLoading = true;

    try {

      AppleID.auth.init({
        clientId: 'co.studenthub.candidate',
        //clientId: 'io.plugn.dash',
        scopes:  'email',//name
        redirectURI: window.location.href,
        state: '12345',
        nonce: 'nonce',
        //usePopup : true
      });

      const data = await AppleID.auth.signIn();

      let params;

      if (data.user && data.user.familyName) {

        Storage.set({
          key: 'appleUserDetail',
          value: JSON.stringify({
            email: data.user.email,
            familyName: data.user.name.familyName,
            givenName: data.user.name.givenName
          })
        }).catch(r => {
          this.eventService.errorStorage$.next(r);
        });

        params = {
          identityToken: data.authorization.id_token,
          email: data.user.email,
          familyName: data.user.name.familyName,
          givenName: data.user.name.givenName
        };
      }
      else
      {
        let oldData = await Storage.get({ key: 'appleUserDetail'});

        params = Object.assign((oldData) ? oldData : {}, {
          identityToken: data.authorization.id_token
        });
      }

      this.handleAppleLoginResponse(params);

    } catch (error) {
      console.error(error);
      // popup_closed_by_user
      this.appleAuthLoading = false;
    }
  }

  /**
   * login by Apple sign in
   */
  async loginByApple() {

    this.appleAuthLoading = true;
     
    let options: SignInWithAppleOptions = {
      clientId: 'io.plugn.dash',
      redirectURI: window.location.href,
      scopes: 'email name',
      state: '12345',
      nonce: 'nonce',
    };

    SignInWithApple.authorize(options)
      .then((result: SignInWithAppleResponse) => {
        this.appleAuthLoading = false;
        this.handleAppleLoginResponse(result);
      })
      .catch(error => {
        this.appleAuthLoading = false;
        this.handleAppleLoginResponse(error);
      });
  }

  /**
   * Login by Google for mobile app
   */
  loginByGoogle() {

    GoogleAuth.signIn().then(async googleUser => {
 
      if (googleUser && googleUser.authentication && googleUser.authentication.idToken) {
        this.useGoogleIdTokenForAuth(googleUser.authentication.idToken, false);
      } else {
        this.eventService.googleLoginFinished$.next({});

        this.showLoginError('Error getting login by Google+ API');
      }
    }).catch(async err => {

      console.error(err);

      this.eventService.googleLoginFinished$.next({});

      if (err = 'popup_closed_by_user') {
        return false;
      }

      this.showLoginError('Error getting login by Google+ API');

    }); 
  }
  
  /**
   * Login by google idToken
   */
  async useGoogleIdTokenForAuth(idToken, showLoader = true) {

    let loading;

    if (showLoader) {
      loading = await this.loadingCtrl.create({
        spinner: 'crescent',
        message: this.translate.transform('Logging in...')
      });
      loading.present();
    }

    const url = environment.apiEndpoint + this._urlLoginByGoogle;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Language: this.translate.currentLang
    });
    
    return this._http.post(url, {
      idToken: idToken,
    }, {
      headers: headers
    })
      .pipe(
        retryWhen(genericRetryStrategy()),
        catchError((err) => this._handleError(err)),
        first(),
        map((res) => res)
      )
      .subscribe(async response => {

        if (response.operation == 'success') {

          this.handleLogin(response, 'Google');

        } else if (response.operation == 'error') {
          const alert = await this.alertCtrl.create({
            message: this.translate.transform('Error getting login by Google+ API'), // JSON.stringify(err)
            buttons: [this.translate.transform('Ok')]
          });
          await alert.present();

        }

        this.eventService.googleLoginFinished$.next({});

      }, err => {

        this.eventService.googleLoginFinished$.next(err);
      },
      () => {
        if (loading) {
          loading.dismiss();
        }
      });
  }

  /**
   * handle response from apple login popup
   * @param data
   */
  async handleAppleLoginResponse(data) {
    
    if (!data || !data.response || !data.response.identityToken) {
      this.appleAuthLoading = false;

      if(data.message && data.message.indexOf("AuthorizationError") == -1) {
        this.showLoginError(this.translate.transform(data.message));
      }

      return null;
    } 

    let params;

    // save user data in first request
  
    if (data.response.givenName) {

      Storage.set({
        key: 'appleUserDetail',
        value: JSON.stringify({
          email : data.response.email,
          familyName : data.response.familyName,
          user : data.response.user,
          givenName : data.response.givenName
        })
      }).catch(r => {
        this.eventService.errorStorage$.next(r);
      });

      params = data.response;
    }
    else {
      let oldData = await Storage.get({ key : 'appleUserDetail'});

      params = Object.assign((oldData) ? oldData : {}, data.response);
    }

    this.useAppleIdTokenForAuth(params);
  }

  /**
   * login/sign up by apple auth code
   * @param params
   */
  useAppleIdTokenForAuth(params) {

    const url = environment.apiEndpoint + this.urlLoginByApple;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Language: this.translate.currentLang
    });

    this._http.post(url, params, {
      headers
    })
        .pipe(
            retryWhen(genericRetryStrategy()),
            catchError(err => this._handleError(err)),
            first(),
            map((res: HttpResponse<any>) => res)
        )
        .subscribe(response => {
 
          this.handleLogin(response, 'Apple');

          this.appleAuthLoading = false;

        }, () => {
          this.appleAuthLoading = false;
        });
  }

  /**
   * Handle response from api call to get login/register by google token or otp
   * @param response
   */
  handleLogin(response, channel) {

    if (response.operation === 'success') {
 
      /*this.analyticsService.track("Log In", { 
        login_method: channel
      })*/

      this.setAccessToken(response, true);

    } else {

      this.alertCtrl.create({
        message: response.message,
        buttons: [this.translate.transform('Okay')]
      }).then(alert => {
        alert.present();
      });
    }
  }

  /**
   * show login error message
   * @param message
   */
  async showLoginError(message = null) {
    const alert = await this.alertCtrl.create({
      message: message? message: this.translate.transform('Error getting login'),
      buttons: [this.translate.transform('Okay')]
    });
    await alert.present();
  }

  /**
   * Login by Auth0 accessToken
   */
   async useTokenForAuth(accessToken, showLoader = true) {

    let loading;

    if (showLoader) {
      loading = await this.loadingCtrl.create({
        spinner: 'crescent',
        message: this.translate.transform('Logging in...')
      });
      loading.present();
    }

    const url = environment.apiEndpoint + this._urlLoginAuth0;

    const headers = this._buildAuthHeaders();

    return this._http.post(url, {
      accessToken: accessToken,
    }, {
      headers: headers
    })
      .pipe(
        retryWhen(genericRetryStrategy()),
        catchError((err) => this._handleError(err)),
        first(),
        map((res) => res)
      )
      .subscribe(async response => {

        if (response.operation == 'success') {

          this.setAccessToken(response, true);

        } else if (response.code == 1) {

          this.auth0Token = accessToken;

          this.router.navigate(['register']);

        } else if (response.operation == 'error') {

          const alert = await this.alertCtrl.create({
            message: this.translate.transform('Error getting login by Auth0 API'), // JSON.stringify(err)
            buttons: [this.translate.transform('Ok')]
          });
          await alert.present();

        }

        //this.eventService.googleLoginFinished$.next({});

      }, err => {

        //this.eventService.googleLoginFinished$.next(err);
      },
      () => {
        if (loading) {
          loading.dismiss();
        }
      });
  }

  /**
   * set app theme
   * @param theme
   */
  setTheme(theme) {

    Storage.set({
      key: 'theme',
      value: theme
    }).catch(r => {
      this.eventService.errorStorage$.next({ error: r });
    });

    this.theme = theme;

    if (theme == 'night') {
      this.renderer.removeClass(document.body, 'day');
      this.renderer.addClass(document.body, 'night');
    } else {
      this.renderer.addClass(document.body, 'day');
      this.renderer.removeClass(document.body, 'night');
    }
  }

  /**
   * Save user data in storage
   */
  saveInStorage() {
    Storage.set({
      key: 'loggedInAgent',
      value: JSON.stringify({
        token: this._accessToken,
        id: this.id,
        agent_name: this.agent_name,
        created_at: this.created_at,
        role: this.role,
        agent_email: this.agent_email,
        store_id: this.store_id,
        language_pref: this.language_pref,
      })
    }).catch(r => {
      this.eventService.errorStorage$.next({ error: r });
    });
  }

  /**
   * Save store when user change
   */
   setStore(store: Restaurant, role = null) {

    this.store = store;
    this.store_id = store? store.restaurant_uuid: null;

    this.role = role;

    if(this.store && environment.production) {

      mixpanel.identify(this.store_id, {
        newDashboard : true,
        $name: this.store.name,
        domain: this.store.restaurant_domain,
        $phone: this.store.owner_phone_country_code + this.store.owner_number,
        $email: this.agent_email,
        $country_code: this.store.country?.iso,
        plan: this.store.activeSubscription && this.store.activeSubscription.plan_id == 2 ? 'Premium plan' : 'Free plan',
        $avatar: 'https://res.cloudinary.com/plugn/image/upload/c_scale,h_105,w_105/restaurants/' + this.store.restaurant_uuid + '/logo/' + this.store.logo,
        tapAccountCreated: this.store.is_tap_enable ? true : false,
        //totalProducts: this.store.totalItems,
        //totalOrders: this.store.totalOrders,
        paymentCash: this.store.isCashEnabled,
        paymentKNET: this.store.isKnetEnabled,
        paymentCreditcard: this.store.isCreditCardEnabled,
        paymentMada: this.store.isMadaEnabled,
        paymentBenefit: this.store.isBenefitEnabled,
        deliveryMashkor: this.store.isMashkorEnabled,
        deliveryArmada: this.store.isArmadaEnabled,
      });

      window.analytics.identify(this.store_id, {
        newDashboard : true,
        name: this.store.name,
        domain: this.store.restaurant_domain,
        phone: this.store.owner_phone_country_code + this.store.owner_number,
        email: this.agent_email,
        plan: this.store.activeSubscription && this.store.activeSubscription.plan_id == 2 ? 'Premium plan' : 'Free plan',
        logo: 'https://res.cloudinary.com/plugn/image/upload/c_scale,h_105,w_105/restaurants/' + this.store.restaurant_uuid + '/logo/' + this.store.logo,
        tapAccountCreated: this.store.is_tap_enable ? true : false,
        //totalProducts: this.store.totalItems,
        //totalOrders: this.store.totalOrders,
        paymentCash: this.store.isCashEnabled,
        paymentKNET: this.store.isKnetEnabled,
        paymentCreditcard: this.store.isCreditCardEnabled,
        paymentMada: this.store.isMadaEnabled,
        paymentBenefit: this.store.isBenefitEnabled,
        deliveryMashkor: this.store.isMashkorEnabled,
        deliveryArmada: this.store.isArmadaEnabled,
      });
    }

    return this.saveInStorage();
  }

  /**
   * start the bell 
   * @returns 
   */
  startTheBell() {

    if(this.setToSilenceMode) { 
      return false;
    }

    this.setStartBell();

    this.audioPlayer.muted = false;

    //this.totalPendingOrders > 0 &&

    if (!this.audioPlayer.played) {
      this.ringTheBell();
    }

    /*else {
      this.audioPlayer.pause();
      this.showPlayButton = false;
    }*/
  }

  /**
   * set bell allowed to start
   */
  setStartBell() {

    this.ringBell = '1';

    Storage.set({
      key: 'ringBell',
      value: '1'
    }).catch(r => {
      this.eventService.errorStorage$.next({ error: r });
    });

    this.showPlayButton = false;
  }

  /**
   * stop started bell 
   */
  stopStartBell() {
    
    Storage.set({
      key: 'ringBell',
      value: '0'
    }).catch(r => {
      this.eventService.errorStorage$.next({ error: r });
    });

    this.ringBell = '0';

    this.showPlayButton = true;
  }

  /**
   * check bell value 
   * @returns 
   */
  async getBell() {

    try {
      let { value } = await Storage.get({ key: 'ringBell' });

      //if not selected

      if(value == null) {
        value = '1';
      }

      return this.setBell(value);
    } catch (error) {
      this.eventService.errorStorage$.next({ error: error });
    }
  }

  setBell(value) {
    this.ringBell = value;
    return value;
  }

  async stopTheBell() {
    await this.stopStartBell();
    this.audioPlayer.muted = true;
    this.audioPlayer.pause();
  }

  /**
   * check permission
   */
  checkSoundPermission() {

    this.audioPlayer.volume = 0;
    this.audioPlayer.muted = true;

    const promise = this.audioPlayer.play();
 
    if (promise !== undefined) {
      promise.then(e => {
 
        this.showPlayButton = false;

      }).catch(error => {

        console.info(error);

        // Autoplay was prevented.
        // Show a "Play" button so that user can start playback.

        this.showPlayButton = true;

        //check permission after interaction 

        setTimeout(() => {
            this.checkSoundPermission();
        }, 1000);
      });
    }
  }

  ringTheBell() {

    Storage.set({
      key: 'ringBell',
      value: '1'
    }).catch(r => {
      this.eventService.errorStorage$.next({ error: r });
    });

    this.ringBell = '1';
    // if (!this.ringBell || this.ringBell == '0') {
    //   this.stopTheBell();
    //   return;
    // }

    const promise = this.audioPlayer.play();

    if (promise !== undefined) {
      promise.then(_ => {
        // Autoplay started!

        this.audioPlayer.volume = 1;
        this.audioPlayer.muted = false;

        this.showPlayButton = false;

      }).catch(error => {

        console.error(error);

        // Autoplay was prevented.
        // Show a "Play" button so that user can start playback.

        this.showPlayButton = true;
      });
    }
  }

  /**
   * Logs a user out by setting logged in to false and clearing token from storage
   * @param {string} [reason]
   * @param {boolean} [silent]
   */
  logout(reason?: string, silent = false) {

    this.stopTheBell();

    this.isLogged = false;

    // Remove from Storage then process logout

    this._accessToken = null;
    this.id = null;
    this.agent_name = null;
    this.created_at = null;
    this.role = null;
    this.agent_email = null;
    this.store_id = null;

    Storage.clear().catch(r => {
      this.eventService.errorStorage$.next({ error: r });
    });

    if(this.utm_uuid) {
      Storage.set({ key: 'utm_uuid', value: this.utm_uuid });
    }

    if (!silent) {
      this.eventService.userLoggedOut$.next(reason ? reason : false);
    }
    
    Storage.set({
      key: 'cookieMessageWasApproved',
      value: (this.displayCookieMessage == '0') ? '1' : '0'
    }).catch(r => {
      this.eventService.errorStorage$.next({ error: r });
    });
  }

  /**
   * Set the access token
   */
  setAccessToken(response, redirect = false) {

    this._accessToken = response.token;
    this.id = response.id;
    this.agent_name = response.agent_name;
    this.created_at = response.created_at;
    this.agent_email = response.agent_email;
    this.store_id = response.selectedStore ? response.selectedStore.restaurant_uuid : response.store_id;

    this.role = response.role;

    this.language_pref = response.language_pref;

    // Save to Storage

    this.saveInStorage();

    // set language pref if user have valid language code saved in DB

    if (response.language_pref) {
      this.eventService.setLanguagePref$.next(response.language_pref);
    }

    if (this._accessToken) {
      this.isLogged = true;
      this.eventService.userLogined$.next({ redirect });
    }
  }

  /**
   * Set initial config
   */
  async load() {

    if(window.location.host.includes('dashboard.plugn.io')) {
      window.location.href = "http://dash.plugn.io";
      return false;
    }

    const promises = [
      Storage.get({ key: 'loggedInAgent' }),
      Storage.get({ key: 'language' }),
      Storage.get({ key: 'currentLocation' }),
      Storage.get({ key: 'utm_uuid' }),
      Storage.get({ 'key': 'setToSilenceMode' }),
    ];

    return Promise.all(promises)
      .then(data => {

        this.getBell();
        
        if(data[4] && data[4].value) {
          this.setToSilenceMode = data[4].value == "true";
        } 

        if(data[3] && data[3].value) {
          this.utm_uuid = data[3].value;
        } else {// if storage failed
          this.utm_uuid = window.localStorage.getItem("utm_id");
        }

        if(data[2] && data[2].value) {
          this.currentLocation = JSON.parse(data[2].value);
        }  

        if (data[1] && data[1].value && typeof data[1].value == "string") {
          try {
            this.language = JSON.parse(data[1].value);
          } catch {

          }

        } else {

          const browserLanguage = navigator.languages
              ? navigator.languages[0]
              : (navigator.language || navigator.userLanguage);

          if (browserLanguage && browserLanguage.indexOf('en') > -1) {
            this.language = {
              code: 'en',
              name: 'English'
            };
          } else {
            this.language = {
              name: 'عربى',
              code: 'ar'
            };
          }
        }

        // for guest use language value in storage, for login user loggedInAgent.language_pref

        const loggedInUser = data[0]? JSON.parse(data[0].value): null;

        if (loggedInUser && loggedInUser.language_pref) {
          this.language = loggedInUser.language_pref == 'ar' ? {
            name: 'عربى',
            code: 'ar'
          }: {
            code: 'en',
            name: 'English'
          };
        }

        this.translate.setDefaultLang('en');

        this.translate.use(this.language.code);

        document.getElementsByTagName('html')[0].setAttribute('dir', (this.language.code == 'ar') ? 'rtl' : 'ltr');

        if (loggedInUser) {
          this.setAccessToken(loggedInUser);
        }

        /*else if (this.cookieService.get('otp')) {
          this._platform.ready().then(_ => {
            setTimeout(() => {
              this.loginByOtp(this.cookieService.get('otp'));
            }, 800);//to fix: https://www.pivotaltracker.com/story/show/168368025
          });
        }*/

        // set direction based on language
        // this._platform.setDir('rtl', true);
        document.documentElement.dir = (this.language.code == 'ar') ? 'rtl' : 'ltr';

      })
      .then(r => {
        // this.eventService.errorStorage$.next({ error: r });
      });

    /*Storage.get({ key: 'cookieMessageWasApproved' }).then(ret => {

        if (ret.value) {
          this.setTheme(ret.value);
        }
      }).catch(r => {
        this.eventService.errorStorage$.next({ error: r });
      });*/
    }

  /**
   * Get Access Token from Service or Cookie
   * @returns {string} token
   */
  getAccessToken(redirect = false) {

    // Return Access Token if set already
    if (this._accessToken) {
      return this._accessToken;
    }

    Storage.get({ key: 'loggedInAgent' }).then(ret => {

      const user = JSON.parse(ret.value);

      if (user) {
        this.setAccessToken(user, redirect);
      }
    }).catch(r => {
      this.eventService.errorStorage$.next({ error: r });
    });

    return this._accessToken;
  }

  /**
   * Basic auth, exchanges access details for a bearer access token to use in
   * subsequent requests.
   * @param  {string} email
   * @param  {string} password
   */
  basicAuth(email: string, password: string): Observable<any> {
    // Add Basic Auth Header with Base64 encoded email and password
    const authHeader = new HttpHeaders({
      Authorization: 'Basic ' + btoa(unescape(encodeURIComponent(`${email}:${password}`))),
      Language: this.language_pref || 'en',
    });
    const url = environment.apiEndpoint + this._urlBasicAuth;

    return this._http.get(url, {
      headers: authHeader,
    }).pipe(
      retryWhen(genericRetryStrategy()),
      first(),
      map((res: HttpResponse<any>) => res)
    );
  }

  isString(x) {
    return Object.prototype.toString.call(x) === "[object String]"
  }

  /**
   * json to string error message
   * @param message
   */
  errorMessage(message): string {

    if (this.isString(message)) {
        return message + '';
    }

    const a = [];

    for (const i in message) {
        
        if (!Array.isArray(message[i])) {
            a.push(message[i]);
            continue;
        }

        for (const j of message[i]) {
            a.push(j);
        }
    }

    return a.join('<br />');
  }

  /**
   * reset password request
   * @param email
   */
  resetPasswordRequest(email: string, token: string = '') {
    const url = environment.apiEndpoint + this._urlResetPassRequest;
    return this._http.post(url, { 'email': email, 'token': token }, { 
      headers: this._buildAuthHeaders() 
    }).pipe(
      retryWhen(genericRetryStrategy()),
      catchError((err) => this._handleError(err)),
      first(),
      map((res) => res)
    );
  }

  /**
   * Verify email
   * @param email
   * @param code
   */
  verifyEmail(email: string, code: string) {
    const url = environment.apiEndpoint + this._urlVerifyEmail;
    const headers = this._buildAuthHeaders();
    return this._http.post(url, { email: email, 'code': code }, { headers: headers }).pipe(
      retryWhen(genericRetryStrategy()),
      catchError((err) => this._handleError(err)),
      first(),
      map((res) => res)
    );
  }

  /**
   * Check if email already verified
   * @param res
   */
  isAlreadyVerified(res): Observable<any> {
    const url = environment.apiEndpoint + this._urlIsEmailVerified;
    return this._http.post(url, res, { headers: this._buildAuthHeaders() }).pipe(
      retryWhen(genericRetryStrategy()),
      catchError((err) => this._handleError(err)),
      first(),
      map((res) => res)
    );
  }

  /**
   * Resend verification email
   * @param email
   */
  resendVerificationEmail(email: string, token: string = '') {
    const url = environment.apiEndpoint + this._urlresendVerificationEmail;
    const headers = this._buildAuthHeaders();
    return this._http.post(url, { 'email': email, 'token': token }, { headers: headers }).pipe(
      retryWhen(genericRetryStrategy()),
      catchError((err) => this._handleError(err)),
      first(),
      map((res) => res)
    );
  }

  /**
   * Update email address
   * @param params params
   */
  updateEmail(params: any): Observable<any> {
    const url = environment.apiEndpoint + this._urlUpdateEmail;
    return this._http.post(url, params, { headers: this._buildAuthHeaders() }).pipe(
      retryWhen(genericRetryStrategy()),
      catchError((err) => this._handleError(err)),
      first(),
      map((res) => res)
    );
  } 

  /**
   * register account
   */
   signup(params): Observable<any> {
    const values = {
      ...params, 
      owner_phone_country_code : params.owner_phone_country_code, 
      owner_number : params.owner_number,
      accept_order_247: params.accept_order_247? 1: 0,
      utm_uuid: this.utm_uuid
    };
    return this._http.post(environment.apiEndpoint + this._urlSignup, values, { headers: this._buildAuthHeaders() }).pipe(
      retryWhen(genericRetryStrategy()),
      catchError((err) => this._handleError(err)),
      first(),
      map((res) => res)
    );
  }

  /**
   * register account
   */
   signupStepOne(params): Observable<any> {
    const values = {
      ...params,
      utm_uuid: this.utm_uuid
    };
    return this._http.post(environment.apiEndpoint + this._urlSignupStepOne, values, { headers: this._buildAuthHeaders() }).pipe(
      retryWhen(genericRetryStrategy()),
      catchError((err) => this._handleError(err)),
      first(),
      map((res) => res)
    );
  }

  /**
   * Update password by password reset token
   * @param token
   * @param newPassword
   */
  updatePassword(newPassword: string, token: string): Observable<any> {
    return this._http.patch(environment.apiEndpoint + this._urlUpdatePass, {
      newPassword,
      token
    }, { headers: this._buildAuthHeaders() }).pipe(
      retryWhen(genericRetryStrategy()),
      catchError((err) => this._handleError(err)),
      first(),
      map((res) => res)
    );
  }

  _buildAuthHeaders() {
    return new HttpHeaders({
      Language: this.language_pref || 'en',
      'Content-Type': 'application/json',
      'Mixpanel-Distinct-ID': this.mixpanel_distinct_id+"" || ""
    });
  }

  /**
   * Handles Caught Errors from All Authorized Requests Made to Server
   * @returns {Observable}
   */
  public _handleError(error: any): Observable<any> {

    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';

    // Handle Bad Requests
    // This error usually appears when agent attempts to handle an
    // account that he's been removed from assigning
    if (error.status === 400) {
      this.eventService.errorStorage$.next({ error: error });
      return EMPTY;
    }

    // Handle No Internet Connection Error /service worker timeout

    if (error.status === 0 || error.status === 504) {
      this.eventService.internetOffline$.next({});
      return EMPTY;
    }

    if (!navigator.onLine) {
      this.eventService.internetOffline$.next({});
      return EMPTY;
    }

    // Handle Expired Session Error
    if (error.status === 401) {
      this.logout('Session expired, please log back in.');
      return EMPTY;
    }

    // Handle Expired Session Error
    if (error.status === 403) {
      this.eventService.errorBlocked$.next({});
      return EMPTY;
    }

    // Handle internal server error - 500
    if (error.status === 500) {
      
      //log to sentry 
      this.sentry.handleError(error);

      this.eventService.error500$.next({});
      return EMPTY;
    }

    // Handle page not found - 404 error
    if (error.status === 404) {

      //log to sentry 
      this.sentry.handleError(error);

      this.eventService.error404$.next({});
      return EMPTY;
    }

    console.log('Error: ' + errMsg);

    return throwError(errMsg);
  }

  _processResponseMessage(response) {
    let html = '';
    for (const i in response.message) {
      for (const j of response.message[i]) {
        html += j + '<br />';
      }
    }

    return html;
  }

  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1:
        return 'opera';
      case agent.indexOf('chrome') > -1:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }
}
